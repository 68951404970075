export default {
  props: {
    bulkSelectedData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tagLoading: false,
      deactivateLoading: false,
      deleteLoading: false,
      selectedUser: {
        key: "",
        user_id: []
      },
      tableSelectUsers: [],
      submitData: new FormData()
    };
  },
  computed: {
    selectedIDs() {
      return this.bulkSelectedData.map(item => item.id);
    }
  },
  methods: {
    async tagBulk() {
      try {
        this.tagLoading = true;
        const formData = new FormData();
        formData.append("user_id[]", this.selectedIDs);
        await this.$store.dispatch("admin/users/bulkTagUsers", formData);
        this.$message.success({
          message: "Users tagged successfully",
          showClose: true
        });
        this.$emit("update:visible", {
          success: true,
          ids: formData.getAll("user_id[]")
        });
      } catch (error) {
        this.$message.error({
          message: error.response.data.message,
          showClose: true
        });
      } finally {
        this.tagLoading = false;
      }
    },
    async deactivateBulk() {
      try {
        this.tagLoading = true;
        const formData = new FormData();
        formData.append("user_id[]", this.selectedIDs);
        await this.$store.dispatch("admin/users/bulkDeactiveUsers", formData);
        this.$message.success({
          message: "Users deactivated successfully",
          showClose: true
        });
        this.$emit("update:visible", {
          success: true,
          ids: formData.getAll("user_id[]")
        });
      } catch (error) {
        this.$message.error({
          message: error.response.data.message,
          showClose: true
        });
      } finally {
        this.tagLoading = false;
      }
    },
    async deleteBulk() {
      try {
        this.tagLoading = true;
        const formData = new FormData();
        formData.append("user_id[]", this.selectedIDs);
        await this.$store.dispatch("admin/users/bulkDeleteUsers", formData);
        this.$message.success({
          message: "Users deleted successfully",
          showClose: true
        });
        this.$emit("update:visible", {
          success: true,
          ids: formData.getAll("user_id[]")
        });
      } catch (error) {
        this.$message.error({
          message: error.response.data.message,
          showClose: true
        });
      } finally {
        this.tagLoading = false;
      }
    }
  }
};