export default {
  name: "toggleOpenSenderID",
  data() {
    return {
      payload: {
        user_id: ""
      },
      rules: {
        type: [{
          required: true,
          message: "Please select a user type",
          trigger: "change"
        }]
      },
      buttonLoading: false
    };
  },
  props: {
    modalPayload: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    showOpenSenderDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getShowDialog: {
      set() {
        this.$emit('closed');
      },
      get() {
        return this.showOpenSenderDialog;
      }
    }
  },
  methods: {
    submitChange() {
      this.buttonLoading = true;
      this.payload.user_id = this.modalPayload.data.id;
      this.$store.dispatch('admin/users/allowOpenSenderID', this.payload).then(() => {
        var _this$modalPayload;
        this.$message({
          type: 'success',
          message: `${(_this$modalPayload = this.modalPayload) === null || _this$modalPayload === void 0 || (_this$modalPayload = _this$modalPayload.data) === null || _this$modalPayload === void 0 ? void 0 : _this$modalPayload.name} is now allowed to create sender IDs with automatic approval.`
        });
        this.$emit('cancelModal');
        this.$emit('refreshTable');
      }).catch(error => {
        this.$message({
          type: 'error',
          message: "There was an error toggling the open sender ID. Please try again later."
        });
      }).finally(() => {
        this.buttonLoading = false;
      });
    },
    preventOpenSenderID() {
      this.buttonLoading = true;
      this.payload.user_id = this.modalPayload.data.id;
      this.$store.dispatch('admin/users/denyOpenSenderID', this.payload).then(() => {
        var _this$modalPayload$da;
        this.$message({
          type: 'error',
          message: `${(_this$modalPayload$da = this.modalPayload.data) === null || _this$modalPayload$da === void 0 ? void 0 : _this$modalPayload$da.name} can no longer create sender IDs with automatic approval.`
        });
        this.$emit('cancelModal');
        this.$emit('refreshTable');
      }).catch(error => {
        this.$message({
          type: 'error',
          message: "There was an error toggling the open sender ID. Please try again later."
        });
      }).finally(() => {
        this.buttonLoading = false;
      });
    }
  }
};