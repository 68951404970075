<template>
  <div
    class="modal fade"
    id="topupaccount"
    tabindex="-1"
    role="dialog"
    aria-labelledby="contact"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content d-flex align-items-center" v-if="success" style="max-width: 400px; margin:0 auto">
        <successMessage :message="success" />
        <button @click="hideModal" class="__close__success__modal">
          Close
        </button>
      </div>
      <div class="modal-content pb-3 modal-main" v-if="!success">
        <div class="modal-header pt-4 pb-2">
          <div
            class="modal-title"
            id="exampleModalLabel"
            style="display: flex !important; justify-content: space-between;'; width:100%;">
            <div class="left-side">
              Top Up
              <span style="font-weight: bold">{{
                payload.data?.name + "'s" || ""
              }}</span>
              Account
            </div>
            <div class="right-side">
              {{
                payload.data?.is_profile_complete === 1
                  ? "individual"
                  : (payload.data?.is_profile_complete === 2 && "corporate") ||
                    ""
              }}
            </div>
          </div>
        </div>
        <div class="modal-body m-0 p-0">
          <div class="row justify-content-center mb-5 mt-5">
            <div class="col-md-10">
              <el-form
                :model="formData"
                :rules="rules"
                ref="ruleForm"
                @submit.prevent.native="checkEnter">
                <div class="row justify-content-center scrollable-modal">
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item label="Amount" prop="amount">
                        <el-input
                          type="text"
                          autocomplete="off"
                          placeholder="Enter amount"
                          v-model.number="formData.amount"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item
                        label="Payment Method"
                        prop="payment_method">
                        <el-select
                          style="width: 100%"
                          v-model="formData.payment_method"
                          filterable
                          placeholder="Select payment method">
                          <el-option
                            v-for="(method, key) in getChartedAccounts"
                            :key="key"
                            :label="method.name"
                            :value="method.account_id"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- Which Account -->
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item label="Mode" prop="mode">
                        <el-select
                          style="width: 100%"
                          v-model="formData.mode"
                          filterable
                          placeholder="Select mode">
                          <el-option :value="1" label="Wallet"></el-option>
                          <el-option :value="2" label="SMS"></el-option>
                          <el-option :value="3" label="Voice"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>

                  <div
                    class="col-md-12"
                    v-if="formData.mode == 2 || formData.mode == 3">
                    <div class="form_input_container">
                      <el-form-item label="Rate" prop="custom_rate">
                        <el-input
                          placeholder="Rate"
                          v-model="formData.custom_rate"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- amount/rate -->
                  <div
                    class="col-md-12"
                    v-if="formData.mode == 2 || formData.mode == 3">
                    <div class="form_input_container">
                      <el-form-item label="Quantity">
                        <el-input
                          placeholder="Quantity"
                          v-model="getDivision"
                          :disabled="true"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-md-12" v-if="formData.mode == 2">
                    <div class="form_input_container">
                      <el-form-item label="SMS Type">
                        <el-select
                          style="width: 100%"
                          v-model="formData.sms_type"
                          filterable
                          placeholder="Select sms type">
                          <el-option
                            v-for="(type, key) in smsTypes"
                            :key="key"
                            :label="type.name"
                            :value="type.id"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div
                    class="col-md-12"
                    v-if="formData.mode == 2 && formData.sms_type == 1">
                    <div class="form_input_container">
                      <el-form-item label="Expiry Duration">
                        <el-select
                          style="width: 100%"
                          v-model="formData.expiry_duration"
                          filterable
                          placeholder="Select expiry duration">
                          <el-option
                            v-for="(duration, key) in expiryDuration"
                            :key="key"
                            :label="duration.name"
                            :value="duration.id"></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- Note -->
                  <div class="col-md-12">
                    <div class="form_input_container">
                      <el-form-item label="Note" prop="notes">
                        <el-input
                          type="text"
                          autocomplete="off"
                          placeholder="Enter note"
                          v-model.number="formData.notes"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <div class="row justify-content-center mb-4">
            <div
              class="col-md-10 modal__footer__btns d-flex justify-content-between mx-3">
              <button style="width: 123px" class="cancel" @click="hideModal">
                Cancel
              </button>
              <el-button
                style=""
                type="danger"
                :loading="loading"
                id="cancelinput"
                @click="submitAction('ruleForm')">
                Top Up Account
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import $ from "jquery";
  import Storage from "@/services/storage.js";

  export default {
    props: {
      payload: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        success: "",
        error: "",
        loading: false,
        formData: {
          amount: "",
          payment_method: "",
          top_up_by: "",
          user_id: "",
          mode: "",
          notes: "",
          custom_rate: "",
          custom_quantity: "",
          account_type: "",
          sms_type: "",
          expiry_duration: "",
        },
        paymentMethods: [
          { id: 1, name: "Mobile Money " },
          { id: 2, name: "Cal Bank" },
          { id: 3, name: "Slydepay" },
          { id: 4, name: "Expresspay" },
          { id: 5, name: "MTN Direct" },
          { id: 6, name: "Zeepay" },
          { id: 7, name: "Cash " },
          { id: 8, name: "Invoice" },
          { id: 9, name: "Bank" },
          { id: 10, name: "Ecobank Bulk" },
          { id: 11, name: "GT Bank" },
          { id: 12, name: "Refund" },
          { id: 13, name: "Bunus" },
        ],

        rules: {
          amount: [
            {
              required: true,
              message: "Please Amount is required.",
              trigger: "change",
            },
            {
              type: "number",
              message: "Amount can only be a number",
            },
          ],
          mode: [
            { required: true, message: "Please select mode.", trigger: "blur" },
          ],
          payment_method: [
            {
              required: true,
              message: "Please select payment method.",
              trigger: "change",
            },
          ],
          notes: [
            { required: true, message: "Note is required", trigger: "blur" },
          ],
          custom_rate: [
            { required: true, message: "Rate is required", trigger: "blur" },
          ],
        },
      };
    },
    computed: {
      getCurrentUserId() {
        let storage = new Storage();
        let adminData = storage.getSavedState("auth.admin").user_id;
        return adminData;
      },
      getChartedAccounts() {
        if (this.disallowedUsers) {
          return store.getters["admin/reporting/chartAccounts"].filter(
            (item) => {
              return (
                item.account_type.includes("Expense") == false &&
                (item.account_id === 9675 ||
                  item.account_id === 9676 ||
                  item.account_id === 9677 ||
                  item.account_id === 9678 ||
                  item.account_id === 9679)
              );
            }
          );
        }
        return store.getters["admin/reporting/chartAccounts"].filter((item) => {
          return item.account_type.includes("Expense") == false;
        });
      },
      getDivision: {
        get() {
          return (
            parseFloat(this.formData.amount) /
              parseFloat(this.formData.custom_rate) || 0
          );
        },
        set(value) {
          return (this.formData.custom_quantity = value);
          // this.formData.amount = parseFloat(this.formData.custom_rate) * parseFloat(this.formData.custom_quantity)
        },
        //return this.formData.custom_quantity = parseFloat(this.formData.amount) / parseFloat(this.formData.custom_rate)
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
      expiryDuration() {
        return [
          {
            id: 1,
            name: "Monthly",
          },
          {
            id: 2,
            name: "Quarterly",
          },
          {
            id: 3,
            name: "Half Year",
          },
        ].filter((item) => {
          if (this.formData.account_type === 1) {
            return item.id === 1;
          }
          return item;
        });
      },
      smsTypes() {
        return [
          {
            id: 1,
            name: "Expiry",
          },
          {
            id: 2,
            name: "Non Expiry",
          },
        ].filter((item) => {
          if (this.formData.account_type === 2) {
            return item.id === 1;
          }
          return item;
        });
      },
    },
    methods: {
      hideModal() {
        this.error = "";
        this.success = "";
        this.loading = false;
        this.$emit("close");
        this.formData = {
          amount: "",
          payment_method: "",
          top_up_by: "",
          user_id: "",
          mode: "",
          notes: "",
          custom_rate: "",
          custom_quantity: "",
          account_type: "",
        };
        $("#topupaccount").modal("hide");
      },
      submitAction(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            this.success = "";
            this.formData.user_id = this.payload.data.id;
            this.formData.top_up_by = this.getCurrentUserId;
            this.formData.custom_quantity = this.getDivision;
            return store
              .dispatch("admin/users/topUpAccount", this.formData)
              .then((message) => {
                this.loading = false;
                this.success = message;
                this.formData.amount = "";
                this.formData.payment_method = "";
                this.$emit("topUpAccountRefresh");
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err.response.data.message);
                if (err?.response?.data == undefined) {
                  this.errors = "Network error try again.";
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
    },
    mounted() {
      this.error = "";
      this.success = "";
      this.loading = false;
    },
    watch: {
      payload: {
        handler() {
          if (this.$props.payload.data) {
            this.formData.account_type =
              this.$props.payload.data.is_profile_complete;
          }
        },
      },
    },
  };
</script>
<style scoped>
  .scrollable-modal {
    overflow: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    height: fit-content;
    max-height: 60vh;
    align-items: center;
    padding-inline: clamp(1rem, 5%, 5rem);
  }

  .modal-dialog {
    max-width: 100%;
    display: flex;
    flex-direction: row;
  }

  .modal-main {
    width: 60%;
    margin: 1rem auto;
    min-width: 300px !important;
  }
</style>
