export default {
  name: "toggleUserType",
  data() {
    return {
      isConfirmationDialogVisible: false,
      payload: {
        user_id: "",
        type: "",
        previousType: ""
      },
      rules: {
        type: [{
          required: true,
          message: "Please select a user type",
          trigger: "change"
        }]
      },
      buttonLoading: false
    };
  },
  props: {
    modalPayload: {
      type: Object,
      default: () => {
        return {};
      },
      required: true
    },
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getShowDialog: {
      set() {
        this.$emit("closed");
      },
      get() {
        return this.showDialog;
      }
    },
    old_profileType() {
      if (this.modalPayload.data) {
        return this.modalPayload.data.is_profile_complete == 1 ? "INDIVIDUAL" : "CORPORATE";
      }
      return this.modalPayload.data.is_profile_complete == 1 ? "INDIVIDUAL" : "CORPORATE";
    },
    new_profileType() {
      return this.payload.type == 2 ? "INDIVIDUAL" : "CORPORATE";
    }
  },
  methods: {
    validateUser() {
      this.$refs.selectTypeForm.validate(valid => {
        if (valid) {
          this.payload = {
            user_id: this.modalPayload.data.id,
            type: this.payload.type,
            previousType: this.modalPayload.data.is_profile_complete
          };
          this.isConfirmationDialogVisible = true;
        } else {
          return false;
        }
      });
    },
    submitChange() {
      this.buttonLoading = true;
      //if payload.type ==3 use another endpoint
      this.$store.dispatch("admin/users/toggleUserCorporateIndividual", this.payload).then(() => {
        this.$message({
          type: "success",
          message: "User type changed successfully!"
        });
        this.isConfirmationDialogVisible = false;
        this.$emit("cancelModal");
        this.$emit("refreshTable");
      }).catch(error => {
        this.$message({
          type: "error",
          message: "There was an error changing the user type. Please try again later."
        });
      }).finally(() => {
        this.buttonLoading = false;
      });
    }
  }
};