import Storage from "../../../services/storage";
export default {
  name: "toggleSubscriptionType",
  data() {
    return {
      isConfirmationDialogVisible: false,
      payload: {
        created_by: "",
        user_id: "",
        status: "",
        previousType: ""
      },
      rules: {
        status: [{
          required: true,
          message: "Please select a subscription type",
          trigger: "change"
        }]
      },
      buttonLoading: false
    };
  },
  props: {
    modalPayload: {
      status: Object,
      default: () => {
        return {};
      },
      required: true
    },
    showSubsciptionTypeModal: {
      status: Boolean,
      default: false
    }
  },
  computed: {
    getShowSubsciptionTypeModal: {
      set() {
        this.$emit("closed");
      },
      get() {
        return this.showSubsciptionTypeModal;
      }
    },
    old_profileType() {
      var _this$modalPayload$da, _this$modalPayload$da2, _this$modalPayload$da3;
      if (((_this$modalPayload$da = this.modalPayload.data) === null || _this$modalPayload$da === void 0 ? void 0 : _this$modalPayload$da.status) == 1) {
        return "Gold";
      } else if (((_this$modalPayload$da2 = this.modalPayload.data) === null || _this$modalPayload$da2 === void 0 ? void 0 : _this$modalPayload$da2.status) == 2) {
        return "Silver";
      } else if (((_this$modalPayload$da3 = this.modalPayload.data) === null || _this$modalPayload$da3 === void 0 ? void 0 : _this$modalPayload$da3.status) == 3) {
        return "Bronze";
      }
    },
    new_profileType() {
      if (this.payload.status == "1") {
        return "Gold";
      } else if (this.payload.status == "2") {
        return "Silver";
      } else if (this.payload.status == "3") {
        return "Bronze";
      }
    },
    checkModalPayloadStatus() {
      var _this$modalPayload;
      return ((_this$modalPayload = this.modalPayload) === null || _this$modalPayload === void 0 || (_this$modalPayload = _this$modalPayload.data) === null || _this$modalPayload === void 0 ? void 0 : _this$modalPayload.status) != this.payload.status;
    },
    getPayloadName() {
      var _this$modalPayload2;
      return ((_this$modalPayload2 = this.modalPayload) === null || _this$modalPayload2 === void 0 || (_this$modalPayload2 = _this$modalPayload2.data) === null || _this$modalPayload2 === void 0 ? void 0 : _this$modalPayload2.name) || "User";
    }
  },
  methods: {
    validateUser() {
      this.$refs.selectTypeForm.validate(valid => {
        if (valid) {
          let storage = new Storage();
          let adminID = storage.getSavedState('auth.admin').user_id;
          this.payload = {
            user_id: this.modalPayload.data.id,
            created_by: adminID,
            status: this.payload.status,
            previousType: this.modalPayload.data.is_profile_complete
          };
          this.isConfirmationDialogVisible = true;
        } else {
          return false;
        }
      });
    },
    submitChange() {
      this.buttonLoading = true;
      this.$store.dispatch("admin/users/storeUserSegments", this.payload).then(() => {
        this.$message({
          type: "success",
          message: "User Subscription changed successfully!"
        });
        this.isConfirmationDialogVisible = false;
        this.$emit("cancelModal");
        this.$emit("refreshTable");
      }).catch(error => {
        this.$message({
          type: "error",
          message: "There was an error changing the user Subscription. Please try again later."
        });
      }).finally(() => {
        this.buttonLoading = false;
      });
    }
  }
};