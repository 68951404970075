var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('el-dialog', {
    attrs: {
      "visible": _vm.getShowDialog,
      "title": "Toggle Open Sender ID",
      "width": "35%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.getShowDialog = $event;
      },
      "closed": function ($event) {
        return _vm.$emit('cancelModal');
      }
    }
  }, [_c('h6', [_vm._v("Warning:")]), _vm.modalPayload.data && _vm.modalPayload.data.open_sender_is_active == 0 ? _c('p', [_vm._v("This will "), _c('span', {
    staticClass: "allow"
  }, [_vm._v("allow")]), _vm._v(" "), _c('strong', [_vm._v(_vm._s(_vm.modalPayload.data.name) + " ")]), _vm._v("to create sender IDs with automatic approval ")]) : _vm._e(), _vm.modalPayload.data && _vm.modalPayload.data.open_sender_is_active == 1 ? _c('p', [_vm._v("This will "), _c('span', {
    staticClass: "prevent"
  }, [_vm._v("prevent")]), _vm._v(" "), _c('strong', [_vm._v(_vm._s(_vm.modalPayload.data.name) + " ")]), _vm._v("to create sender IDs with automatic approval ")]) : _vm._e(), _vm.modalPayload.data ? _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancelModal');
      }
    }
  }, [_vm._v("Cancel")]), _vm.modalPayload.data.open_sender_is_active == 0 ? _c('el-button', {
    attrs: {
      "loading": _vm.buttonLoading,
      "type": "danger"
    },
    on: {
      "click": _vm.submitChange
    }
  }, [_vm._v("Approve")]) : _c('el-button', {
    attrs: {
      "loading": _vm.buttonLoading,
      "type": "danger"
    },
    on: {
      "click": _vm.preventOpenSenderID
    }
  }, [_vm._v("Deny")])], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };